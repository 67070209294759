import PropTypes from "prop-types"
import React from "react"
import '../utils/bootstrap-4.3.1/css/bootstrap.min.css'

const Footer = ({ siteTitle }) => (
  <footer className={"footer"}>
    <div className="row">
      <div className={"col-md-4"}>
        <h3 className={"footer-usp"}>Analytics & Data Science<br/> on-demand</h3>
      </div>
      <div className={"col-md-8"}>
        <div className="row">
          <div className={"col-12 col-md-4"}>
            <h4>Company</h4>
            <ul>
              <li>About us</li>
              <li>How it works</li>
              <li>Packages</li>
            </ul>
          </div>
          <div className={"col-12 col-md-4"}>
            <h4>Services</h4>
            <ul>
              <li>Analytics Consulting</li>
              <li>Product analytics</li>
              <li>Data Science teams</li>
            </ul>
          </div>
          <div className={"col-12 col-md-4"}>
            <h4>Essay (blog)</h4>
            <ul>
              <li>Starting a customer centric strategy, a brief guide</li>
              <li>Tracking conversations in a startup</li>
              <li>How to collect feedback (and proccess it)</li>
            </ul>
          </div>
        </div>
      </div>
      {/*<div className="col-sm-12 col-md-12 subfooter">*/}
        {/*Made with <span className="heart">♥</span> from LND / NYC / MAD */}
      {/*</div>*/}
      {/*<div className="clarfix visible-md-block visible-sm-block"></div>*/}
      {/*<div className="col-12-md">*/}
        {/*<hr />*/}
      {/*</div>*/}
      {/*<div className="clarfix visible-md-block"></div>*/}
      {/*<div className="col-sm-6 col-md-3"><a href="mailto:contact@thedatacompany.co">Zrite us</a></div>*/}
      {/*<div className="col-sm-6 col-md-4"><p>29-31 Oxford St, London W1D 2DR</p></div>*/}
      {/*<div className="clearfix visible-sm-block visible-md-block"></div>*/}
      {/*<div className="col-sm-12 col-md-5 text-sm-left text-md-right text-lg-right"><p>© Copyright {new Date().getFullYear()},*/}
        {/*TheDataCompany.co Ltd.*/}
      {/*</p></div>*/}
    </div>
    <div className={"row"}>
      <div className={"footer-detail"}>
        <div className={"col"}>
          <p className={"text-right"}>We are a distributed company, by design. | ©2019 TheDataCompany.co</p>
        </div>

      </div>
    </div>
  </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
