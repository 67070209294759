import React from 'react';

class Calendly extends React.Component {
  // Lifecycle
  componentDidMount() {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    const estilo = document.createElement('link');
    estilo.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.css');
    estilo.setAttribute('rel', 'stylesheet');
    script.setAttribute('src',  'https://assets.calendly.com/assets/external/widget.js');
    head.appendChild(estilo);
    head.appendChild(script);
  }

  render() {
    return (
      <div>
        <div
          className={"calendly-inline-widget"}
          data-url="https://calendly.com/datacompany/intros"
          style={{ minWidth:'320px' , height:'630px'}} />
      </div>
    )
  }
}

export default Calendly;