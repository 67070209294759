import React, { Component } from "react"
import { graphql } from "gatsby"
import Img from 'gatsby-image';
import PropTypes from "prop-types"
import SEO from "../components/seo"
import Layout from "../components/layout"


class PostTemplate extends Component {
  render() {
    const post = this.props.data.wordpressPost

    return (
      <Layout>
        <SEO title={post.title} />
        <h1 dangerouslySetInnerHTML={{ __html: post.title }} />
        <div className={"featured-image"}>
          { post.featured_media && (
            <Img
              src={post.featured_media.localFile.childImageSharp.sizes.src}
              sizes={post.featured_media.localFile.childImageSharp.sizes}
              alt="blog_image_one"
              className="img-fluid"
            />
          )}
        </div>
        <div dangerouslySetInnerHTML={{ __html: post.content }} />
      </Layout>
    )
  }
}

PostTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export default PostTemplate

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPost(id: { eq: $id }) {
      title
      content
      excerpt
      slug
      date(formatString: "MMMM DD, YYYY")
      
    }
    site {
      siteMetadata {
        title,
        
      }
    }
  }
`
