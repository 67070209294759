import PropTypes from "prop-types"
import React from "react"
import logo from '../images/icon.png'
import '../utils/bootstrap-4.3.1/css/bootstrap.min.css'
import {Navbar, Nav} from 'react-bootstrap'
import OpenModal from './OpenModal';

const Header = ({ siteTitle }) => (
  <header
    style={{
      background: `white`,
      marginBottom: `1.45rem`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        // maxWidth: 960,
        maxWidth: 1160,
      }}
    >
          <Navbar expand="lg" variant="light" className={"navbar-custom"}>
            <Navbar.Brand href="/" className={"d-lg-inline-block align-middle"}><img src={logo} alt={"Referral Program Logo"} className={"inline-block"} width={"46px"} height={"46px"}/>{siteTitle}</Navbar.Brand>
            <Navbar.Toggle aria-controls="navbar-nav" />
            <Navbar.Collapse id="navbar-nav" className={"collapse navbar-collapse flex-grow-1 text-righ"}>
              <Nav className="navbar-nav ml-auto flex-nowrap">
                <Nav.Link href="/packages">Packages</Nav.Link>
                <Nav.Link className={"align-middle"} href="/posts">Data science Essays</Nav.Link>
                <OpenModal
                  btn_class={"btn btn-primary-nav"}
                />
              </Nav>
            </Navbar.Collapse>
          </Navbar>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
